<template>
	<div>
		<a-config-provider :locale="zh_CN">
			<a-card>
				<a-row style="margin-bottom: 20px;">
					<a-col :span="24">
						<a-range-picker @change="DiscoverytimeChange" :placeholder="[l('WEStartDate'), l('WEEndDate')]" v-model="nowTime"
						 style=" width: 250px;text-align: center;margin-right:10px;" />
						<a-select mode="multiple" :filterOption="filterOption"
							style="width: 176px;text-align: left;margin-right: 10px;" :maxTagCount="1"
							:placeholder="l('issueprogress.Progress')" @change="IssuseProcessChange" allow-clear>
							<a-select-option v-for="item in issuseProcessList" :key="item.progress"
								:title="item.itemDetailName">
								{{ item.name }}
							</a-select-option>
						</a-select>
						 <a-tree-select
						         v-model="orgId"
						         style="width: 200px"
						         :tree-data="treeDataList"
						         :replace-fields="treeReplaceFields"
						         :dropdown-style="{ maxHeight: '400px', overflow: 'auto',width: '300px' }"
						         :placeholder="l('please_select_organization_structure')"
						 		:dropdownMatchSelectWidth="false"
						         allow-clear
						         tree-default-expand-all
								 @change="treeRepChange"
						 >
						 </a-tree-select>
						 <a-select showSearch :filterOption="filterOption"
						     style="width: 150px;text-align: left;margin-left: 10px;"
						     dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
						     :placeholder="l('Please_select_a_user_level')" @change="LevelChange" allow-clear>
						     <a-select-option v-for="item in LevelList" :key="item.levelIndex"
						         :title="item.name">
						         {{ item.name }}
						     </a-select-option>
						 </a-select>
					</a-col>
				</a-row>
				<a-spin :spinning="isLoading">
					<div id="data" style="height: 0px;text-align: center;font-size: 2rem;line-height: 100px;"></div>
					<div style="height: 720px;width: 100%;text-align: left" id="mainScrapCode">
	
					</div>
				</a-spin>
			</a-card>
		</a-config-provider>
	</div>
</template>

<script>
	import {
		AppComponentBase
	} from "@/shared/component-base";
	import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
	import {
		IssueProgressServiceProxy,LayoutLevelServiceProxy,ReportServiceProxy,LayoutOrgServiceProxy
	} from '../../../shared/service-proxies';
	import moment from "moment";
	import * as echarts from 'echarts';
	
	let _this;
	export default {
		name: "Personnel-problem-statistics",
		mixins: [AppComponentBase],
		components: {
	
		},
		created() {
			_this = this;
			this.IssueProgressServiceProxy = new IssueProgressServiceProxy(this.$apiUrl, this.$api);
			this.LayoutLevelServiceProxy = new LayoutLevelServiceProxy(this.$apiUrl, this.$api);
			
			this.ReportServiceProxy = new ReportServiceProxy(this.$apiUrl, this.$api);
			this._LayoutOrgServiceProxy = new LayoutOrgServiceProxy(this.$apiUrl, this.$api);
		},
		mounted() {
			this.newData();
			this.getissueProgress();
			this.getLevelData();
			this.getTreeData();
			this.getData();
		},
		data() {
			return {
				zh_CN,
				isEdit: false,
				isLook: false,
				isLoading: true, //加载中
				issuseProcessClass: [],
				issuseProcessList: undefined,
				LevelList: [],
				LevelId: undefined,
				
				orgId: undefined,
				startDate: undefined,
				endDate: undefined,
				nowTime: [],
				
				respList: [],
				progressNameList: [],
				chartData: [],
				data: undefined,
				treeDataList: [],
				treeReplaceFields: {
				    title: 'levelNameAndName',
				    key: 'id',
				    value: 'id'
				},
				color: []
			}
		},
		computed: {
			// 指定图表的配置项和数据
			option() {
				return {
					title: {
						text: this.l('Personnel-problem-statistics'),
						subtext: '',
						x: 'center',
						textStyle: {
							fontSize: 26,
						},
					},
					tooltip: {
						trigger: 'axis',
					},
					color: this.color,
					legend: {
						top: '97%'
					},
					calculable: true,
					xAxis: [{
						type: 'category',
						name: '',
						data: this.respList,
						splitLine: {
							show: true
						}
					}, ],
					yAxis: [{
							type: 'value',
							name: this.l('Plan_Count'),
							min: 0,
							axisLabel: {
								formatter: '{value}',
							},
						}
					],
					series: this.chartData,
				}
			}
		},
		methods: {
			moment,
			newData() {
				let startTime = new Date().getFullYear() + '-01-01';
				let endTime = new Date().getFullYear() + '-12-31';
				this.nowTime = [moment(startTime), moment(endTime)];
				this.startDate = moment(startTime);
				this.endDate = moment(endTime);
			},
			getData() {
				this.isLoading = true;
				this.ReportServiceProxy.getRespIssueStatistics(
					this.startDate,
					this.endDate,
					this.issuseProcessClass,
					this.orgId,
					this.LevelId
				).finally(() => {
					this.isLoading = false;
				}).then(res => {
					this.data = res.data;
					this.progressNameList = res.progressNameList;
					this.color = [];
					for(let name of this.progressNameList){
						for(let item of this.issuseProcessList){
							if(item.name === name) {
								this.color.push(item.foreColor);
							}
						}
					}
					this.respList = res.respList;
					this.chartsData();
				})
			},
	
			chartsData() {
				this.chartData = [];
				for (let progress of this.progressNameList) {
					let progressData = {
						name: progress,
						type: 'bar',
						stack: 'A',
						data: []
					};
					for (let value of this.data) {
						if(value.name === progress) {
							progressData.data.push(value.problemCount);
						}
					}
					this.chartData.push(progressData);
				}
				// this.chartData = [];
				// this.chartData.push(discoveriesNumber);
				// this.chartData.push(closeNumber);
				// this.chartData.push(closeRate);
				this.charts();
			},
	
			charts() {
				this.myeChart = echarts.init(document.getElementById("mainScrapCode"))
	
				this.myeChart.clear();
				
				document.getElementById("data").style.height = "0px";
				document.getElementById("data").innerHTML = "";
				
				// 使用刚指定的配置项和数据显示图表。
				document.getElementById("mainScrapCode").style.height = "720px";
				this.myeChart.setOption(this.option);
				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},
			
			getTreeData() {
			    this.isLoading = true;
			    this._LayoutOrgServiceProxy.getAllData(
			    )
			        .finally(() => {
			            this.isLoading = false;
			        })
			        .then((res) => {
			            this.treeDataList = JSON.parse(JSON.stringify(res));
			        })
			},
			
			//获取用户层级
			getLevelData() {
				this.isLoading = true;
				this.LayoutLevelServiceProxy.getPaged(
					
				).finally(() => {
					this.isLoading = false;
				}).then(res => {
					this.LevelList = res.items;
				})
			},
			
			LevelChange(value) {
			    this.LevelId = value;
			    this.getData();
			},
			
			//选择组织架构
			treeRepChange(key) {
				this.getData();
			},
			
			//获取进度值
			getissueProgress() {
				this.isLoading = true;
				this.IssueProgressServiceProxy.getPaged().finally(() => {
					this.isLoading = false;
				}).then(res => {
					this.issuseProcessList = res.items;
				})
			},
			
			IssuseProcessChange(value) {
				this.issuseProcessClass = value;
				console.log(value)
				this.getData();
			},
			
			filterOption(input, option) {
				return (
					option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
				);
			},
			
			DiscoverytimeChange(date, dateString) {
				this.startDate = date[0];
				this.endDate = date[1];
				this.getData();
			}
		}
	}
</script>

<style>
</style>